<template>
  <v-fade-transition mode="out-in">
    <v-container grid-list-xs>
      <!-- <h1>{{ dados }}</h1> -->
    </v-container>
  </v-fade-transition>
</template>

<script>
import { BASE_URL } from "@/configs/api";

export default {
  components: {},
  data() {
    return {
      dados: [],
    };
  },
  mounted() {
    this.$axios
      .get(BASE_URL + "/listarProdutos")
      .then((response) => {
        this.dados = response.data.data;
        setTimeout(() => {
          // this.loading = false;
        }, 1500);
      })
      .catch((error) => {});
  },
  computed: {},
  methods: {
    getKeyword() {
      let keyword = this.keyword;
      // alert("PESQUISANDO..." + this.keyword);
    },
  },
};
</script>

<style lang="scss"></style>
